import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyA2VfY_Xo7l47e4-DzX1TIfmWhlsJDl75g",
    authDomain: "sapienkid-3a923.firebaseapp.com",
    databaseURL: 'https://sapienkid-3a923.firebaseio.com',
    projectId: "sapienkid-3a923",
    storageBucket: "sapienkid-3a923.appspot.com",
    messagingSenderId: "708932243978",
    appId: "1:708932243978:web:54d7201acb44f6c0ac45f3",
    measurementId: "G-5DF0TGDSWL"
};

firebase.initializeApp(firebaseConfig);


//public key:  BG-VTXudnVd9TZmpgsjKXLT5PCuhvLKIx_ZdCJqQGNWqBU0iIaHwnxImkSBDInJR0P_tGUfbWvDlIVqsOjkOP44
//private key:  DgMwqW5RXRVtrE_I5_usLcVi6fPdMG3Th6XuoIkhD7E

export default firebase;
