import React, { useState, useEffect, useContext } from "react";
import API from "../../API";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {

  MDBInput,
  
} from 'mdb-react-ui-kit';

const myInitialValues = { parentList:""};
function PlanMaster () {
  // const [isOn, setIsOn] = React.useState(false);
  const[data,setData]=useState([]);
  const[list,setList]=useState([]);
  // const [list,setParentlist]=useState([]);
  const [req, setReq] = useState({
    Name:""});
  const [course, setCourse] = useState("");
  const options = {
    sizePerPageList: [
        {
            text: '50', value: 50
        },
        {
            text: '500', value: 500
        },
        {
            text: '1000', value: 1000
        },
        {
            text: '10000', value: 10000
        }
    ]
};
  const id="";
  useEffect(()=>{
    API.get("Parent/list")
    .then(res=> setData(res.data))
    .catch(err=>console.log(err))
  },[]);

  
  useEffect(() =>
  {
  getData();
  },
  []);
const[table,settable]=useState();
  const getData = () =>{
    API.get("plan/list").then((res) =>
    {
      console.log(res.data);
      settable(res.data)
    }
    );
};
  
  const columns = [
        { text: 'Sr No', dataField: 'id' },
        { text: 'Vertical Name', dataField: 'name' },
        { text: 'Sub Vertical Name', dataField: 'vname' },
        { text: 'Plane Active', dataField: 'active ', }, 
  ];
const onChange = e =>{
  setList({...list,[e.target.mobile]:[e.target.value]});

}

const onsubmitHandler = e =>{
  e.preventDefault();
  console.log(list);
}
  
  return (
  
    <div>
      <div className="container-fluid add_mobview" style={{ marginTop: "100px" }}>
        <div className="card">
          <div className="col-lg-12 col-md-12 col-sm-12">
              <h1 className="page-title">
              <center>Plan Master</center>
              </h1>
              <div className="card-body">
              <Formik  >
            
          <Form onSubmit={onsubmitHandler}>
             
            <label htmlFor="heading" className="col-sm-4 col-md-12 col-lg-12 col-form-label">
              Enter Mobile Number :
            </label>
            <MDBInput label='' id='mobile' type='tel' className="col-md-5" placeholder="Enter Your Registered Phone Number" onChange={onChange}/>        
            <div className="text-center">
           
           <button type="submit" className="btn btn-primary">Submit</button><br/>
           </div>
            <br />
          
              
          
            <br />
            <label htmlFor="heading" className="col-sm-4 col-md-12 col-lg-12 col-form-label">
              Select Child :
            </label>
            <Field  as="select" className="form-control form-control-sm" >            
              <option value="">Please Select Type</option>{data &&
              data.map((c, index) => (<>
              <option value={data.id} key={c.id}>{c.id} - {c.name} </option> </>
              ))  }        
            </Field>  <br/>
            <div className="text-center">
           
            
            </div>
              
          </Form>
              
      </Formik>
     <br/>
      <div className="card-header"><h5>Manage Plans</h5></div>
      {/* { table && 
                <div className="card-body  ">
                    <BootstrapTable keyField="data.id" data={table} columns={columns} pagination={paginationFactory(options)}/>
                </div>
                } */}
                </div>
              </div>
          </div>
        </div>
      </div>     
  )  
}

export default PlanMaster










// import React, {Component } from 'react'
// import axios from 'axios';
// // import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// // import './App.css';
// import API from "../../API";
// export class PlanMaster extends Component {
// constructor(props) {
// super(props)
// this.state = {
// StateId: '',
// CountryId: '',
// CountryData: [],
// StateData: [],
// CityData: []
// }
// }
// componentDidMount() {
// API.get('Parent/list.').then(response => {
// console.log(response.data);
// this.setState({
// CountryData: response.data
// });
// });
// }
// ChangeteState = (e) => {
// this.setState({
// CountryId: e.target.value
// });
// API.get('/child/list' + e.target.value).then(response => {
// console.log(response.data);
// this.setState({
// StateData: response.data,
// });
// });
// }
// ChangeCity = (e) => {
// this.setState({
// StateId: e.target.value
// });
// axios.get('http://localhost:65173/get-cities-by-state?StateId=' + e.target.value).then(response => {
// console.log(response.data);
// this.setState({
// CityData: response.data
// });
// });
// }
// render() {  
// return (  
// <div>  
// <div class="row" className="hdr">  
// <div class="col-sm-12 btn btn-info">  
// Cascading Dropdown in ReactJS  
// </div>  
// </div>  
// <div className="form-group dropdn">  
// <select className="form-control" name="country" value={this.state.CountryId} onChange={this.ChangeteState}  >  
// <option>Select Country</option>  
// {this.state.CountryData.map((e, key) => {  
// return <option key={key} value={e.CountryId}>{e.CountryName}</option>;  
// })}  
// </select>  
// <select className="form-control slct" name="state" value={this.state.StateId} onChange={this.ChangeCity} >  
// <label for="company">State Name</label>    
// {this.state.StateData.map((e, key) => {  
// return <option key={key} value={e.StateId}>{e.StateName}</option>;  
// })}  
// </select>  
// <select className="form-control slct" name="city" value={this.state.CityData}  >  
// {this.state.CityData.map((e, key) => {  
// return <option key={key} value={e.CityId}>{e.cityName}</option>;  
// })}  
// </select>  
// </div>  
// </div>  
// )  
// }  
// }  
// export default PlanMaster 





































// import React, { useState, useEffect, useContext } from "react";
// import API from "../../API";
// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
// import { useDispatch, useSelector } from "react-redux";
// import BootstrapTable from "react-bootstrap-table-next";
// import { Markup } from "interweave";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";



// async function PlanMaster() {
//   useEffect(()=>{
//     fetch("http://127.0.0.1:8000/Parent/list").then((data)=>data.json()).then((val)=>console.log(val,"demoval"))
//   })
//     const [data, setData] = useState();
//    {
//           await API.get("Parent/list", { params: { id: `${data}` } })
//             .then((res) => {
//               if (res.data.status === "success") {
//                 // console.log(res.data.data)
//                 setCourse(res.data);
//               }
//             })
//             .catch((error) => {
//               console.log("error:", error);
//             });
//         }
   
      
  // return(
  //   <Formik>
  //   <div className="row">
  //       <div className="col-md-12">
  //           <div className="card">
  //               <h1 className="page-title">
  //                   <center>Plan Master</center>
  //               </h1>
  //                   <div className="card-body">
                    {/* <DropdownButton id="dropdown-basic-button" title="Dropdown button">
      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
    </DropdownButton> */}
{/* <Formik
        initialValues={myInitialValues}
        onSubmit={(values, { resetForm }) => {
          saveInstituteDetails(setCourse, values.instituteList);
          resetForm();
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <label
              htmlFor="heading"
              className="col-sm-4 col-md-12 col-lg-12 col-form-label"
            >
              Select Parent :
            </label>
            <Field
              name="instituteList"
              as="select"
              value={values.instituteList}
              className="form-control form-control-sm"
            >
              <option value="">Please Select Type</option>
              {instituteList &&
                instituteList.map((rec, index) => (
                  <option value={rec.username} key={index}>
                    {rec.username}-{rec.name}
                  </option>
                ))}
            </Field>
            <ErrorMessage name="institute">
              {(msg) => <div className="alert alert-danger">{msg}</div>}
            </ErrorMessage>
            <br />
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </Form>
         )} 
      </Formik>
      <br />
       {data ? (
        <>
          <BootstrapTable
            cellEdit={!confirm ? cellEdit : false}
            keyField="srno"
            columns={columns}
            data={data}
            remote={{ cellEdit: value == "err" ? true : false }}
          />
          <br />
          <div className="text-center">
            <button
              className="btn btn-warning text-center"
              onClick={() => {
                handleShow();
              }}
            >
              Confirm
            </button>
          </div>
        </>
      ) : null} 

                     </div>
                 </div>
             </div>
         </div>
    
     </Formik>
  );
  
};
            




export default PlanMaster; */}
