import {createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setMessage } from './loginSlice';
import API from '../../API';

export const  fetchDeleteMessageList=createAsyncThunk(
    'Delete/account/fetch', async(params,{dispatch})=>{
        await API.get("Delete/accounts", { params: params})
        .then(res=>{
            dispatch(setMessage({
                show: true,
                msg: res.data.message,
                responseType:  res.data.status
              }));
            dispatch(setDeleteUserList(res.data.data));
        })
        .catch(err=>{
            dispatch(setMessage({
                show: true,
                msg: err.response.data.message,
                responseType: err.response.data.status
              }));

            }) 
       
    }
)

export const  deleteRecord=createAsyncThunk(
    'Delete/account', async(recordId,{dispatch})=>{
        await API.post("User/account/remove", { id: recordId })
        .then(res=>{
            dispatch(setMessage({
                show: true,
                msg: res.data.message,
                responseType:  res.data.status
              }));
            dispatch(updateDeleteUserList(res.data.data));
        })
        .catch(err=>{
            dispatch(setMessage({
                show: true,
                msg: err.response.data.message,
                responseType: err.response.data.status
              }));

        }) 
       
    }
)


export const  reserveRecord=createAsyncThunk(
    'Delete/account', async(recordId,{dispatch})=>{
        await API.post("User/account/reserve", { id: recordId })
        .then(res=>{
            dispatch(setMessage({
                show: true,
                msg: res.data.message,
                responseType:  res.data.status
              }));
            dispatch(updateDeleteUserList(res.data.data));
        })
        .catch(err=>{
            dispatch(setMessage({
                show: true,
                msg: err.response.data.message,
                responseType: err.response.data.status
              }));

        }) 
       
    }
)

export const deleteAccountSlice = createSlice({
  name: 'delAccount',
  initialState: {
    delUsrList: [],
    curPage:1,
    totalPages:''
 },

  reducers: {
    setDeleteUserList: (state,action) => {
        state.delUsrList = action.payload
    },
    updateDeleteUserList: (state,action) =>{
        const record = action.payload;
        state.delUsrList = state.delUsrList.map(rec=>rec.id === record.id ? record : rec);
    }
 
  },
 
})

// Action creators are generated for each case reducer function
export const { setDeleteUserList,updateDeleteUserList } = deleteAccountSlice.actions

export default deleteAccountSlice.reducer