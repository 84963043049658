export const paginationOptions = {
    sizePerPageList:[
        
        {
            text:'10',value:10
        },
        {
            text:'20',value:20
        },
        {
            text:'30',value:30
        },
        {
            text:'40',value:40
        },
    ]
}