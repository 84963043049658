import React, { useState, useEffect } from "react";
import Calender from "../FormFields/Calender";
import HelpRecordTable from "./HelpRecordTable";
import API from "../../API";
import { useSelector, useDispatch } from 'react-redux'
import { resolveHelpMessage, fetchHelpMessageList } from "../../Store/AllReducer/helpCenterSlice";

const HelpMessageList = () => {
    const [datelist, setDateList] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const { helpUsrList } = useSelector((state) => state.help);
    const { currentUser } = useSelector((state) => state.login)
    const dispatch = useDispatch()

    useEffect(() => {
        getDateList(setDateList);
    }, []);

    useEffect(() => {
        if (selectedDate)
            dispatch(fetchHelpMessageList({ date: selectedDate }))

    }, [selectedDate]);


    const handleResolve = (recordId) => {
        dispatch(resolveHelpMessage({ id: recordId }))
    }
    return currentUser ? (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="float-right page-breadcrumb">
                        </div>
                        <h5 className="page-title">Help Center</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-1">
                                        <b>Date :</b>
                                    </div>

                                    <div className="col-6">
                                        <Calender
                                            id="date"
                                            name="date"
                                            onChange={e => setSelectedDate(e.target.value)}
                                            style={{ width: "300px" }}
                                        />
                                        {/* <Dropdown
                                        id="date"
                                        name="date"
                                        dataType="date"
                                        onChange={e => setSelectedDate(e.target.value)}
                                        list={datelist}
                                        valueColumnName="date"
                                        labelColumnName="date"
                                    /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid " style={{ marginTop: "2%" }}>
                <div className="row card">
                    <HelpRecordTable listData={helpUsrList} handleResolve={handleResolve} />
                </div>
            </div>
        </div>
    ) : null;
};

async function getDateList(setDateList) {
    await API.get("Help/dates")
        .then(res => {
            if (res.data.status === "success") {
                setDateList(res.data.data);
            }
        })
        .catch(err => {

        })
}


export default HelpMessageList;