import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    currentUser: {},
    message: {
      show: false,
      msg: '',
      responseType: ''
    },

  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    setMessage: (state, action) => {
      state.message = action.payload
    },
    setLogout: (state) => {
    
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentUser, setMessage, setLogout } = loginSlice.actions

export default loginSlice.reducer