import { React, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory,{textFilter,numberFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../Constants";
import { Link } from "react-router-dom";
import ModalPopup from "../Modals/Modal";
const DeleteAccountTable = (props) => {
    const { listData, handleDelete, handleCancel } = props;
    const [tableData, setTableData] = useState({
        header: [],
        data: [],
    });
    const [messagePopup,showMessgePopup] =useState({
        show:false,
        msg:""
    });

    const handleModal = ()=>{
        showMessgePopup({
            show:false,
            msg:""
        });
    }

    useEffect(() => {
        setTableData({
            header: getHeader(),
            data: getData(listData, handleDelete, handleCancel,showMessgePopup),
        });

    }, [listData]);

    return (
        <>
        <div className="container-fluid">
            <div className="row card">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="float-right page-breadcrumb"></div>
                    <h3 className="page-title">
                        <center>Records</center>
                    </h3>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 ">
                    {tableData.data.length ? (
                        <div style={{ marginBottom: "20px" }}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="card mt-3">
                                            <div className="card-body">
                                                <BootstrapTable
                                                    keyField="srno"
                                                    data={tableData.data}
                                                    columns={tableData.header}
                                                    headerClasses="allTable_header"
                                                    filter={filterFactory()}
                                                    pagination={paginationFactory(paginationOptions)}
                                                    striped
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
        <ModalPopup show={messagePopup.show} setShow={handleModal} title={"Message : "} msg={messagePopup.msg}/>
        </>
    );
};

function getData(data, handleDelete, handleCancel,showMessgePopup) {
    let myData = [];
    let i = 1;

    data && data.length &&
        data.map((data, index) => {
            myData.push({
                id: i++,
                issue_id: data.issue_id,
                pname: data.parentName,
                pemail: data.parentEmail,
                cname: data.childName,
                message: <>{data.message.slice(0,15)}....<font color="blue" className="view_more_msg" onClick={()=>showMessgePopup({show:true,msg:data.message})}>view more</font></>,
                reason: data.title,
                status: data.cancelled === "YES" ? <font color="green">Cancelled</font> : (data.deleted==="YES" ? <font color="red">Deleted</font>: <font color="red"> - </font>),
                cancel: data.cancelled === "YES" ? "-" : <button className="btn-success" onClick={() => handleCancel(data.id)}>Cancel</button>,
                delete: data.deleted === "YES" ? "-" : <button className="btn-danger" onClick={() => handleDelete(data.id)}>Delete</button>

            });
        });
    return myData;
}

function getHeader() {
    let myHeader = [
        { text: "Sr No", dataField: "id",sort:true},
        { text: "Issue Id", dataField: "issue_id"},
        { text: "Parent Name", dataField: "pname"},
        { text: "Parent Email", dataField: "pemail"},
        { text: "Child Name", dataField: "cname"},
        { text: "Message", dataField: "message" },
        { text: "Reason", dataField: "reason" },
        { text: "Status", dataField: "status" },
        { text: "Cancel", dataField: "cancel" },
        { text: "Delete", dataField: "delete" },
    ];
    return myHeader;
}

export default DeleteAccountTable;
