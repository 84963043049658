import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import API from "../../../API";

const AddVerticalForm = ({ myList, setMyList, currentUser, dispatch, setMessage }) => {
  const myInitialValues = {
    name: "",
    description: "",
    iconLink: "",
    video1: "",
    video2: "",
    video3: "",
    video4: "",
  };

  return currentUser ? (
    <Formik
      initialValues={myInitialValues}
      onSubmit={async (values, actions) => {
        saveVertical(values, dispatch, setMessage, myList, setMyList);
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Vertical Name is Required"),
        description: Yup.string().required("Vertical Description is Required."),
      })}
    >
      {(props) => {
        const {
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div>
            <div className="col-lg-12">
              <form onSubmit={handleSubmit}>
                <div className="card m-b-30">
                  <div className="card-header">
                    <b>Vertical Master Form</b>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="p-20">
                          <div className="form-group">
                            <label>Vertical Name</label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.name ? <div className="alert alert-dark">{errors.name}</div> : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-20">
                          <div className="form-group">
                            <label>Vertical Description</label>
                            <input
                              type="text"
                              name="description"
                              id="description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.description ? <div className="alert alert-dark">{errors.description}</div> : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-20">
                          <div className="form-group">
                            <label>Icon Link</label>
                            <input
                              type="text"
                              name="iconLink"
                              id="iconLink"
                              value={values.iconLink}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.iconLink ? <div className="alert alert-dark">{errors.iconLink}</div> : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-20">
                          <div className="form-group">
                            <label>Video1 Link</label>
                            <input
                              type="text"
                              name="video1"
                              id="video1"
                              value={values.video1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.video1 ? <div className="alert alert-dark">{errors.video1}</div> : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-20">
                          <div className="form-group">
                            <label>Video2 Link</label>
                            <input
                              type="text"
                              name="video2"
                              id="video2"
                              value={values.video2}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.video2 ? <div className="alert alert-dark">{errors.video2}</div> : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-20">
                          <div className="form-group">
                            <label>Video3 Link</label>
                            <input
                              type="text"
                              name="video3"
                              id="video3"
                              value={values.video3}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.video3 ? <div className="alert alert-dark">{errors.video3}</div> : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-20">
                          <div className="form-group">
                            <label>Video4 Link</label>
                            <input
                              type="text"
                              name="video4"
                              id="video4"
                              value={values.video4}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.video4 ? <div className="alert alert-dark">{errors.video4}</div> : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-20">
                          <div className="form-group">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="form-group">
                      <center>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
      }}
    </Formik>
  ) : null;
};

async function saveVertical(values, dispatch, setMessage, myList, setMyList) {
  await API.post("/Vertical", { 'name': values.name, 'description': values.description, 'iconLink': values.iconLink, 'video1': values.video1, 'video2': values.video2, 'video3': values.video3, 'video4': values.video4 })
    .then(function (res) {
      if (res.data.status === "success") {
        dispatch(setMessage({
          show: true,
          msg: res.data.message,
          responseType: res.data.status
        }));
        setMyList(!myList);
      }
    })
    .catch(function (err) {
      dispatch(setMessage({
        show: true,
        msg: err.response.data.message,
        responseType: err.response.data.status
      }));
    });
}

export default AddVerticalForm;
