import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import API from '../../../API';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DeleteConfirmationPopUp from "../../Modals/DeleteConfirmationModal";

const VerticalList = ({ myList, setMyList, edit, setEdit, setEditData, currentUser, dispatch, setMessage }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [delId, setDelId] = useState();
    const [visible, setVisible] = useState(false);

    const options = {
        sizePerPageList: [
            {
                text: '50', value: 50
            },
            {
                text: '500', value: 500
            },
            {
                text: '1000', value: 1000
            },
            {
                text: '10000', value: 10000
            }
        ]
    };

    useEffect(() => {
        getVerticalList(setData, dispatch, setMessage);
    }, [myList]);


    const header = getHeader();
    const myData = getData(data, setVisible, setDelId, edit, setEdit, setEditData);

    return (
        currentUser && data !== undefined ?
            <div style={{ "marginBottom": "20px" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                  
                                    <BootstrapTable keyField='srno' data={myData} columns={header} filter={filterFactory()} pagination={paginationFactory(options)} />

                                    {visible ?
                                        <DeleteConfirmationPopUp visible={visible} setVisible={setVisible} popupMsg={"Do you really want to delete this Vertical?"} deleteRecord={deleteRecord} id={delId} setInserted={setMyList} inserted={myList} dispatch={dispatch} setMessage={setMessage} setCurPage={1} />
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : null
    );
};

async function deleteRecord(id, setMyList, myList, dispatch, setMessage) {
    await API.delete('/Vertical/' + id)
        .then(function (res) {
            if (res.data.status === 'success') {
                dispatch(setMessage({
                    show: true,
                    msg: res.data.message,
                    responseType: res.data.status
                }));
                setMyList(!myList);
            }
        })
        .catch(function (err) {
            dispatch(setMessage({
                show: true,
                msg: err.response.data.message,
                responseType: err.response.data.status
            }));
        });
}

function getData(data, setVisible, setDelId, edit, setEdit, setEditData) {
    let myData = [];
    let i = 1;

    data !== undefined && data.map((data, index) => {
        myData.push({
            srno: i++,
            verticalName: data.name,
            description: data.description,
            edit: <center><button className="btn btn-warning" onClick={() => { editRecord(data, setEdit, setEditData); }}>Edit</button></center>,
            delete: <center><button className="btn btn-danger" onClick={() => { setVisible(true); setDelId(data.id); }}>Delete</button></center>
        });
    });

    return myData;
}

function editRecord(data, setEdit, setEditData) {
    setEdit(true);
    setEditData(data);
}

function getHeader() {
    let myHeader = [
        { text: 'Sr No', dataField: 'srno' },
        { text: 'Vertical Name', dataField: 'verticalName' },
        { text: 'Description', dataField: 'description' },
        { text: 'Edit', dataField: 'edit' },
        { text: 'Delete', dataField: 'delete' },
    ];
    return myHeader;
}

async function getVerticalList(setData, dispatch, setMessage, setMyList, myList) {
    await API.get("/Vertical")
        .then(function (res) {
            if (res.data.status === "success") {
                setData(res.data.data);
                setMyList(!myList);
            }
        })
        .catch(function (error) {

        });
}

export default VerticalList;