import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setMessage } from './loginSlice';
import API from '../../API';

export const fetchHelpMessageList = createAsyncThunk(
    'HelpCenter/fetch', async (params, { dispatch }) => {
        await API.get("Help/records", { params: params })
            .then(res => {
                if (res.data.status === "success") {
                    console.log("Help Res:", res.data.data);
                    dispatch(setHelpMessageList(res.data.data));
                    dispatch(setMessage({
                        show: true,
                        msg: res.data.message,
                        responseType: res.data.status
                    }));
                }
            })
            .catch(err => {
                dispatch(setMessage({
                    show: true,
                    msg: err.response.data.message,
                    responseType: err.response.data.status
                }));
            })
    }
)

export const resolveHelpMessage = createAsyncThunk(
    'HelpCenter/resolve', async (params, { dispatch }) => {

        await API.get("Help/resolve", { params: params })
            .then(res => {
                if (res.data.status === "success") {
                    dispatch(updateHelpMessageList(res.data.data));
                    dispatch(setMessage({
                        show: true,
                        msg: res.data.message,
                        responseType: res.data.status
                    }));

                }
            })
            .catch(err => {
                dispatch(setMessage({
                    show: true,
                    msg: err.response.data.message,
                    responseType: err.response.data.status
                }));
            })
    }
)

export const helpCenterSlice = createSlice({
    name: 'helpRecords',
    initialState: {
        helpUsrList: [],
        curPage: 1,
        totalPages: ''
    },

    reducers: {
        setHelpMessageList: (state, action) => {
            state.helpUsrList = action.payload
        },
        updateHelpMessageList: (state, action) => {
            const record = action.payload;
            state.helpUsrList = state.helpUsrList.map(rec => rec.id === record.id ? record : rec);
        }

    },

})

// Action creators are generated for each case reducer function
export const { setHelpMessageList, updateHelpMessageList } = helpCenterSlice.actions

export default helpCenterSlice.reducer