import React, { useState } from "react";
import UploadVerticalMaster from "./UploadVerticalMaster";
import VerticalList from "./VerticalList";
import EditVertical from "./EditVertical";
import AddVerticalForm from "./AddVerticalForm";
import { useSelector, useDispatch } from 'react-redux'
import { setMessage } from "../../../Store/AllReducer/loginSlice";
const VerticalMaster = () => {
  const [myList, setMyList] = useState(true);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState();
  const { currentUser } = useSelector((state) => state.login)
  const dispatch = useDispatch()

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="float-right page-breadcrumb"></div>
            <h5 className="page-title">Vertical Master</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <UploadVerticalMaster myList={myList} setMyList={setMyList} currentUser={currentUser} dispatch={dispatch} setMessage={setMessage} />
          </div>
          <div className="col-lg-8">
            <AddVerticalForm myList={myList} setMyList={setMyList} currentUser={currentUser} dispatch={dispatch} setMessage={setMessage} />
          </div>
          <div className="col-lg-12" style={{ marginTop: "20px" }}>
            <VerticalList
              myList={myList}
              setMyList={setMyList}
              edit={edit}
              setEdit={setEdit}
              setEditData={setEditData}
              currentUser={currentUser}
              dispatch={dispatch}
              setMessage={setMessage}
            />
          </div>
          <div>
            {edit ? (
              <EditVertical
                edit={edit}
                setEdit={setEdit}
                setEditData={setEditData}
                editData={editData}
                setMyList={setMyList}
                myList={myList}
                currentUser={currentUser}
                dispatch={dispatch}
                setMessage={setMessage}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalMaster;
