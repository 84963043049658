import React from "react";
import { Echart } from '../../Echart';
function SubscribedUser() {
    const option = {
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [120, 200, 150, 80, 70, 110, 130],
                type: 'line'
            }
        ]
    };
    return (

        <>
            <Echart option={option} />
        </>
    );
}
export default SubscribedUser;