import axios from 'axios';
const serverPath = process.env.REACT_APP_SERVER_PROJPATH;

let API = axios.create({
  baseURL: serverPath
});

API.interceptors.request.use(
  function (config) {
    
    if (window.location.pathname !== "/" || window.location.pathname !== "/login") {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);  
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response;
    let errRes = { ...error };
    if (status !== undefined && status.status === 401) {
      window.location.href = "/login";
    } else if (status !== undefined && status.status === 429) {
      errRes = {
        'response': {
          data: {
            message: "Server is Busy. Please wait for some seconds. Your Response will not be saved till this message keeps appearing."
          }
        }
      }

    } else if (status === undefined || !status) { 
      errRes = {
        'response': {
          data: {
            message: "There is some problem with server response.Your Response will not be saved till this message keeps appearing."
          }
        }
      }
    }
    if (!error.response) {
      errRes = {
        'response': {
          data: {
            message: "Your Connection to server is lost. Please Check your internet Connection."
          }
        }
      }
    }
    return Promise.reject(errRes);
  }
);

export default API;