import React from "react";
import { Card } from "react-bootstrap";
function EchartCard(props) {
    const { content, className, style } = props;
    return (
        <div className={className} style={style}>
            <Card >
                {content}
            </Card>
        </div>
    );
}


export default EchartCard;