import React, { useState, useEffect, useContext } from "react";
import API from "../../API";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import { Echart } from '../../Echart';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBInput,
  
} from 'mdb-react-ui-kit';
// import { MDBContainer } from "mdbreact";
import { Doughnut } from "react-chartjs-2";
const myInitialValues = { parentList:""};
function ChildrenProfile () {
  const [isOn, setIsOn] = React.useState(false);
  const[data,setData]=useState([]);
  const [list,setParentlist]=useState([]);
  const [req, setReq] = useState({
    Name:""});
  const [course, setCourse] = useState("");
  const options = {
    sizePerPageList: [
        {
            text: '50', value: 50
        },
        {
            text: '500', value: 500
        },
        {
            text: '1000', value: 1000
        },
        {
            text: '10000', value: 10000
        }
    ]
};
const [fillActive, setFillActive] = useState('tab1');
const handleFillClick = (value: string) => {
  if (value === fillActive) {
    return;
  }

  setFillActive(value);
};
const datas = {
  labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      {
        label: "Hours Studied in Geeksforgeeks",
        data: [2, 5, 6, 7, 3],
        backgroundColor: ["blue", "green", "yellow", "pink", "orange"],
      }
    ]
}
  const id="";
  useEffect(()=>{
    API.get("Parent/list")
    .then(res=> setData(res.data))
    .catch(err=>console.log(err))
  },[]);

  
  useEffect(() =>
  {
  getData();
  },
  []);
const[table,settable]=useState();
  const getData = () =>{
    API.get("plan/list").then((res) =>
    {
      // console.log(res.data);
      settable(res.data)
    }
    );
};
  
  // console.log(table[0].name)

  const columns = [
        { text: 'Sr No', dataField: 'id' },
        { text: 'Vertical Name', dataField: 'name' },
        { text: 'Sub Vertical Name', dataField: 'vname' },
        // { text: 'Gender', dataField: 'gender' },
        // { text: 'Date of Birth', dataField: 'dob' },
        { text: 'Plane Active', dataField: 'active ' }, 
  ];
  // option = {
  //   title: {
  //     text: 'A Case of Doughnut Chart',
  //     left: 'center',
  //     top: 'center'
  //   },
  //   series: [
  //     {
  //       type: 'pie',
  //       data: [
  //         {
  //           value: 335,
  //           name: 'A'
  //         },
  //         {
  //           value: 234,
  //           name: 'B'
  //         },
  //         {
  //           value: 1548,
  //           name: 'C'
  //         }
  //       ],
  //       radius: ['40%', '70%']
  //     }
  //   ]
  // };
  const optionsSimple = ["Potatoes", "Apples", "Bananas", "Mango"];
// const handleChange = (e, index) =>{
//   const{name, value} = e.target;
//   const parentlist = [...list];
//   list[index][name] = value;
//   setParentlist(parentlist);
//   console.log("pass")
// }
async function saveInstituteDetails(setCourse, value) {
  await API.get("plan/list"+{id}, { params: { uid: `${value}` } })
    .then((res) => {
      if (res.data.status === "success") {
        // console.log(res.data.data)
        setCourse(res.data.data);
      }
    })
    .catch((error) => {
      console.log("error:", error);
    });
  }
  useEffect(() => {
    if (course.length > 0) {
      getData(setData, course);
    }
  }, [course]);
  // const Parentlist =[data.map(item=>item.name)]
  // console.log(Parentlist);

  const selectState = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    req[name] = value;
    var data = value;
    alert(data)
    
    var response = fetch("/child/list",{
      method: "Post",
      headers:{
        "content-type":"application/json",
      },
      body: JSON.stringify({id:data}),
    }).then(function(response) {
      return response.json();
    })
    .then(function(myjson){
      setReq(myjson)
    }
    )
  };
  return (
  
    <div>
      <div className="container-fluid add_mobview" style={{ marginTop: "100px" }}>
        <div className="card">
          <div className="col-lg-12 col-md-12 col-sm-12">
              <h1 className="page-title">
              <center>Plan Master</center>
              </h1>
              <div className="card-body">
              <Formik  >
              {({ values, handleChange }) => (
          <Form>
            <label htmlFor="heading" className="col-sm-4 col-md-12 col-lg-12 col-form-label">
              Enter Mobile Number :
            </label>
            <MDBInput label='' id='typePhone' type='tel' className="col-md-5" placeholder="Enter Your Registered Phone Number"/>        
            {/* <Field  as="select" className="form-control form-control-sm" >
            
              <option value="">Please Select Type</option>
             
              {data.map((e, index) => (<>
              <option value={data.id} key={e.id}  onChange={(event) => {setParentlist(event.target.value)}}>{e.id} - {e.name}{  <button onClick={() => setIsOn((prevState) => 
!prevState)}></button>} </option> </>
              ))}            
            </Field>     */}
            <br />
            <div className="text-center">
            <button type="submit" className="btn btn-primary">Submit</button><br/>
            </div>
              
          </Form>
              )}
      </Formik>
     <br/>
      <div className="card-header"><h5>Select Child To View Profile</h5></div>
        <MDBTabs fill className='mb-8'>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
              Child 1 Name
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
              Child 2 Name
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleFillClick('tab3')} active={fillActive === 'tab3'}>
              Child 3 Name
            </MDBTabsLink>
           </MDBTabsItem>
          </MDBTabs>
          <MDBTabsContent>
            <MDBTabsPane show={fillActive === 'tab1'}><section style={{ backgroundColor: '#eee' }}>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol>
            <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4">
              <h3>Children Profile</h3>
            </MDBBreadcrumb>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                  fluid />
                <p className="text-muted mb-1">Name - Suraj K</p>
                <p className="text-muted mb-4">Logic Code - 4954318</p>
                <div className="d-flex justify-content-center mb-2">
                  <MDBBtn>Edit</MDBBtn>
                  {/* <MDBBtn outline className="ms-1">Message</MDBBtn> */}
                </div>
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4 mb-lg-0">
            {/* <span className="text-primary font-italic me-1">Vertical </span> */}
              <MDBCardBody className="p-0">
                <MDBListGroup flush className="rounded-3">
                <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                   
                    <span className="text-primary font-italic me-1">Verticals </span>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fas icon="globe fa-lg text-warning" />
                    <MDBCardText>Health</MDBCardText>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fab icon="github fa-lg" style={{ color: '#333333' }} />
                    <MDBCardText>Organising</MDBCardText>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fab icon="twitter fa-lg" style={{ color: '#55acee' }} />
                    <MDBCardText>Relation</MDBCardText>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fab icon="instagram fa-lg" style={{ color: '#ac2bac' }} />
                    <MDBCardText>Mindfulness</MDBCardText>
                  </MDBListGroupItem> 
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fab icon="facebook fa-lg" style={{ color: '#3b5998' }} />
                    <MDBCardText>Creativity</MDBCardText>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fab icon="facebook fa-lg" style={{ color: '#3b5998' }} />
                    <MDBCardText>Education & Career</MDBCardText>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fab icon="facebook fa-lg" style={{ color: '#3b5998' }} />
                    <MDBCardText>Money Mangement</MDBCardText>
                  </MDBListGroupItem>
                </MDBListGroup>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Full Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">Suraj Kathare</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">Suraj@bynaric.in</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Phone</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">(097) 234-5678</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Date Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">19-04-2010</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Gender</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">Male</MDBCardText>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            {/* <MDBContainer>
      <Doughnut data={datas} />
    </MDBContainer> */}
            {/* <MDBCol lg='7'>
      <MDBChart
        type='doughnut'
        data={{
          labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          datasets: [
            {
              label: 'Traffic',
              data: [2112, 2343, 2545, 3423, 2365, 1985, 987],
              backgroundColor: [
                'rgba(63, 81, 181, 0.5)',
                'rgba(77, 182, 172, 0.5)',
                'rgba(66, 133, 244, 0.5)',
                'rgba(156, 39, 176, 0.5)',
                'rgba(233, 30, 99, 0.5)',
                'rgba(66, 73, 244, 0.4)',
                'rgba(66, 133, 244, 0.2)',
              ],
            },
          ],
        }}
      />
    </MDBCol> */}
     <>
            {/* <Echart option={option} /> */}
        </>
            <MDBRow>
              <MDBCol md="6">
                <MDBCard className="mb-4 mb-md-0">
                  <MDBCardBody>
                    <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Sub-Vertical </span> Status</MDBCardText>
                    <MDBCardText className="mb-1" style={{ fontSize: '.77rem' }}>Teeth</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={80} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Hygeine</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={72} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Healthy Food</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={89} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Sun Salutation</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Physical Fitness</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Good Posture</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Sound Sleep</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Body Changes</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Avoid Addictions</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Get up early</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Make my bed</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Dress Neatly</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                    </MDBProgress>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol md="6">
                <MDBCard className="mb-4 mb-md-0">
                  <MDBCardBody>
                    <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Sub-Vertical</span> Status</MDBCardText>
                    <MDBCardText className="mb-1" style={{ fontSize: '.77rem' }}>Orderliness</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={80} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Planning</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={72} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Arrange Events</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={89} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Family</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>I Am Me</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>	
Friendship</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Emotions</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Communication</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Kindness & Manners</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Praying</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>	
Self -Talk</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Yoga</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>
                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Pranayam & Meditation</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section> </MDBTabsPane>
            <MDBTabsPane show={fillActive === 'tab2'}>Tab 2 content</MDBTabsPane>
            <MDBTabsPane show={fillActive === 'tab3'}>Tab 3 content</MDBTabsPane>
          </MDBTabsContent>
                </div>
              </div>
          </div>
        </div>
      </div>     
  )  
}

export default ChildrenProfile
