import React from "react";
import { hideShow } from "../utils/Helper";
import API from "../API";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import {setLogout} from "../Store/AllReducer/loginSlice";

const Header = () => {
  const currentUser = useSelector((state) => state.login.currentUser)
  const dispatch = useDispatch()

  let navigate = useNavigate();
  return (
    <div>
      <div className="topbar">
        <div className="topbar-left	d-none d-lg-block">
          <div className="text-center">
            <a href="#" className="logo">
              <img src="assets/images/logo.png" height={60} alt="logo" />
            </a>
          </div>
        </div>
        <nav className="navbar-custom">
          <ul className="list-inline menu-left mb-0">
            <li className="list-inline-item">
              <button
                type="button"
                className="button-menu-mobile open-left waves-effect"
                onClick={() => {
                  hideShow();
                }}
              >
                <i className="ion-navicon" />
              </button>
            </li>
            {currentUser ?
            <li className="list-inline-item dropdown notification-list" style={{"float":"right"}}>
              <a
                className="nav-link dropdown-toggle arrow-none waves-effect nav-user"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <img
                  src="assets/images/admin.png"
                  alt="user"
                  className="rounded-circle"
                />
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown ">
                <a className="dropdown-item" href="#" onClick={() => {logout(currentUser,navigate,dispatch, setLogout);}}>
                  <i className="mdi mdi-logout m-r-5 text-muted" /> Logout
                </a>
              </div>
            </li>
            :null}
          </ul>
          <div className="clearfix" />
        </nav>
      </div>
    </div>
  );
};

async function logout(currentUser,navigate,dispatch, setLogout)
{
  await API.post('Authenticate/logout', { "id":currentUser.id})
  .then(res => 
  {
    if (res.data.status === 'success') 
    {
        localStorage.clear();
        dispatch(setLogout());
        navigate('/login');
    }
  })
  .catch(function (error) 
  {
    localStorage.clear();
    dispatch(setLogout());
    navigate('/login');
  });
}


export default Header;
