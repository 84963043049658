import React from "react";
import Alert from "react-bootstrap/Alert";
import { useEffect } from "react";
import { Markup } from "interweave";
import { useSelector, useDispatch } from 'react-redux'
import {connect} from "react-redux";
import {setMessage} from "./Store/AllReducer/loginSlice";
function AlertDismissible(props) {
  const { show,msg,responseType} = props;
  const dispatch = useDispatch()

  useEffect(() => {
    if(show){
      const interval = setTimeout(() => {
        dispatch(setMessage({
          show: false,
          msg: '',
          responseType: ''
        }))
      }, 10000);
  
      return () => clearInterval(interval);
    }
   
  }, [show]);

  const handleCloseAlert=()=>{
    dispatch(setMessage({
      show: false,
      msg: '',
      responseType: ''
    }))
  }
  return show && msg ? (
    <div>
    <div
      className={responseType==="success"? "alert alert-success alert-dismissible fade show": "alert alert-danger alert-dismissible fade show"}
      role="alert"
      style={{
        position: "fixed",
        top: "73%",
        width: "70%",
        left: "22%",
        zIndex: "99999",
      }}
    >
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={handleCloseAlert}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      {msg}
    </div>
    </div>
  ) : null;
}

function mapStateToProps(state) {
  const { login } = state
  return  login.message ;
}

export default connect(mapStateToProps)(AlertDismissible)
