import $ from 'jquery';
import API from '../API';
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({});

export function hideShow()
{
  
  $('#sidebar').toggleClass('active');

    let body = document.getElementById('body');
    let wrapper = document.getElementById('wrapper');
    let boxes = document.querySelectorAll('.box');
    let boxposition=document.querySelectorAll('.position');
    // let width = document.getElementById('sidebar').value();
    
    if(body.className.indexOf('fixed-left-void') !== -1)
    {
     
        body.classList.remove('fixed-left-void');
        //  wrapper.classList.remove('enlarged');
        document.getElementById("sidebar").style.width = '240px';
        document.getElementById("hidespacecontent").style.marginLeft='240px';
        boxes.forEach(box => {
          box.style.display='inline-block'
          document.getElementById("arrowposition").style.paddingRight="70px";
        });
    }
    else
    {
      body.classList.add('fixed-left-void');
      document.getElementById("sidebar").style.width = '90px';
      document.getElementById("arrowsubmenu").style.display='flex';
      boxes.forEach(box => {
        // box.style.fontSize='14px';
        box.style.display='none'
      });
      
      // boxposition.forEach(position => {
      //   position.style.display='flex';
      //   position.style.justifyContent='center';
      // });
      document.getElementById("hidespacecontent").style.marginLeft='109px';
      document.getElementById("footermargin").style.marginLeft='-139px';
        // wrapper.classList.add('enlarged');
    }
   
    
}

export function collapseMenuinner(){
  var elm_width = document.getElementById("sidebar").style.width;
 
  
  document.getElementById("innerlist").style.width = '240px';
  document.getElementById("innerlist").style.backgroundColor = 'white';
  document.getElementById("hidespacecontent").style.marginLeft='240px';
  /*if(elm_width){
    if(elm_width == '90px'){
           document.getElementById("hidespacecontent").style.marginLeft='240px';
    }
  }else{
    document.getElementById("hidespacecontent").style.marginLeft='240px';
  }*/

}

export function collapseiconMenuinner(){
  
  var elm_width = document.getElementById("sidebar").style.width;
   

  document.getElementById("innerlist").style.width = '240px';
  document.getElementById("innerlist").style.backgroundColor = 'white';
  document.getElementById("hidespacecontent").style.marginLeft='240px';
  /*if(elm_width){
    if(elm_width == '90px'){
           document.getElementById("hidespacecontent").style.marginLeft='109px';
    }
  }else{
    document.getElementById("hidespacecontent").style.marginLeft='240px';
  }*/

}



export function hide()
{
    let body = document.getElementById('body');
    let wrapper = document.getElementById('wrapper');
    body.classList.add('fixed-left-void');
    wrapper.classList.add('enlarged');
}

export function collapseMenu()
{
    $('.has_sub').each(function () {
        var t = $(this);
        if (t.hasClass('nav-active')) 
        {
            t.find('> ul').slideUp(300, function () {
                t.removeClass('nav-active');
            });
        }
        else
        {
            t.find('> ul').slideDown(300, function () {
                t.addClass('nav-active');
            });
        }
    });
   
}



  
  //   $('#closebutton').each(function() {
  //     if ($(window).width() > 500) { //your chosen mobile res
  //       $('.text').toggle(300);
  //     } else {
  //       $('#sidebar-menu').animate({
  //         width: 'toggle'
  //       }, 350);
  //     }
  // });


export function searchStringInArray (str, strArray) {
    for (var j=0; j<strArray.length; j++) {
        if (strArray[j].match(str)) return j;
    }
    return -1;
}

export function setupAxios(setShow, setMsg,navigate) {
    API.interceptors.request.use(
      function (config) {
        if (browserHistory.location.pathname !== "/") {
          const token = JSON.parse(localStorage.getItem("token"));
          if (token) {
            config.headers["Authorization"] = "Bearer " + token;
          }
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  
    API.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response;
        if (status !== undefined && status.status === 401) {
          if (window.location.href.search("linkLogin/") === -1) {
            navigate("/login");
          }
        } else if (status !== undefined && status.status === 429) {
          setShow(true);
          setMsg(
            "Server is Busy. Please wait for some seconds. Your Response will not be saved till this message keeps appearing."
          );
        } else if (status === undefined || !status) {
          setShow(true);
          setMsg(
            "There is some problem with server response.Your Response will not be saved till this message keeps appearing."
          );
        }
        if (!error.response) {
          setShow(true);
          setMsg(
            "Your Connection to server is lost. Please Check your internet Connection."
          );
        }
        return Promise.reject(error);
      }
    );
  }