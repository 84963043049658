import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

const AdminHome = (props) => {
  // const { currentUser } = useContext(UserContext);
  const { state } = useLocation();
  const currentUser = useSelector((state) => state.login.currentUser)

  return currentUser ? (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="float-right page-breadcrumb">
            </div>
            <h5 className="page-title">Admin Home</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AdminHome;
