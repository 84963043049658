import React from 'react';

const Spinner = () => {
    return (
        <div>
            <div id="status" style={{"marginTop":"20px"}}><div className="spinner"></div></div>
        </div>
    );
};

export default Spinner;