import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

function NotificationForm(props) {
    const {token,saveToken,dispatch, setMessage,currentUser} = props;
    const myInitialValues = {
        token:token
    
    };
    return (
        currentUser ?
            <div>
                <div className="container-fluid">
                    <div className="row card">
                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <Formik
                                initialValues={myInitialValues}
                                onSubmit={async (values, actions) => {
                                    saveToken(token,currentUser.id,dispatch, setMessage);
                                    console.log("values:", values);
                                    actions.setSubmitting(false);
                                    actions.resetForm({
                                        values: myInitialValues,
                                    });
                                }}
                              
                            >
                                {
                                    props => {
                                        const {
                                            values,
                                            errors,
                                            touched,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue
                                        } = props;
                                        return (
                                            <form id="form-Prog" method="post" className="form-horizontal" onSubmit={handleSubmit}>
                                                <div className="card mt-3">

                                                    <div className="card-body">
                                                        <div className="form-group">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 row">
                                                                <div className="col-lg-6 col-md-6 col-sm-12 row">
                                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                                        Token
                                                                    </div>
                                                                    <div className="col-lg-9 col-md-9 col-sm-12">
                                                                        <input
                                                                            type="text"
                                                                            name="token"
                                                                            id="token"
                                                                            value={token}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            className="form-control"
                                                                        />

                                                                        {errors.token && touched.token && (
                                                                            <div className="alert alert-danger">
                                                                                {errors.token}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 row">
                                                                <button
                                                                        type="submit"
                                                                        className="btn btn-primary"
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        Save Token
                                                                    </button>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                              
                                                    </div>


                                                </div>
                                            </form>
                                        );
                                    }
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            : null
    );
}

export default NotificationForm;