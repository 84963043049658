import React from "react";
import { hideShow } from "../utils/Helper";

const Footer = () => {
  return (
    <div>
      <footer className="footer" id="footermargin">
        © 2022 <b>Sapien Kids</b>
      </footer>
    </div>
  );
};

export default Footer;
