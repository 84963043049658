import React from "react";
import { date } from "yup";

function Calender(props) {
    const { id, name, className="form-control", onChange ,style} = props;
    return (
        <>
           <input type="date" name={date} id={id} onChange={onChange} style={style} className={className}/>
        </>
    );
}

export default Calender;