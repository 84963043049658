import React, { useEffect, useState } from "react";
import API from "../../API";
import firebase from "./Firebase";
import NotificationForm from "./NotificationForm";
import { setMessage } from "../../Store/AllReducer/loginSlice";
import { useSelector, useDispatch } from 'react-redux'

function SendNotification() {
    const [token, setToken] = useState();
    const { currentUser } = useSelector((state) => state.login)

    const dispatch = useDispatch()

    const startFCM = () => {
        const messaging = firebase.messaging();

        messaging.requestPermission().then(function () {
            return messaging.getToken();
        })
            .then(function (response) {
                setToken(response);
            }).catch(function (error) {
                alert(error);
            });
        messaging.onMessage(function (payload) {
            console.log("App FCM Payload: ",payload);
            const title = payload.notification.title;
            const options = {
                body: payload.notification.body,
                icon: payload.notification.icon,
            };
            new Notification(title, options);
        });
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="float-right page-breadcrumb">
                        </div>
                        <h5 className="page-title">Notification</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <button className="btn btn-success" onClick={startFCM}> Get Token </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <NotificationForm saveToken={saveToken} token={token} dispatch={dispatch} setMessage={setMessage} currentUser={currentUser}/>
                    </div>
                </div>
                {token &&
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <button className="btn btn-primary" onClick={() => sendNotification(token)}> Send Notification </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );


}

async function saveToken(token, id, dispatch, setMessage) {
    const params = {
        firebasekey: token,
        id: id,
    }
    await API.post("Firebase/token", params)
        .then((res) => {
            dispatch(setMessage({
                show: true,
                msg: res.data.message,
                responseType: res.data.status
            }));
                })
        .catch(err => {
            dispatch(setMessage({
                show: true,
                msg: err.response.data.message,
                responseType: err.response.data.status
            }));
        })
}

async function sendNotification(token) {

    await API.get("Admin/notification", { params: { token: token } })
        .then((res) => {
            console.log("Res: ", res);


        })
        .catch(err => {
            console.log("Error: ", err.response);

        })
}


export default SendNotification;