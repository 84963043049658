import {createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../API';

export const  fetchUserCountByRole=createAsyncThunk(
    'Chart/usersCountByRole', async(params,{dispatch})=>{
        await API.get("Chart/users/role")
        .then(res=>{
            console.log("res: ",res);
           dispatch(setUserCountByRole(res.data.data));
        })
        .catch(err=>{
            console.log("err: ",err);

        }) 
       
    }
)


export const graphSlice = createSlice({
  name: 'graph',
  initialState: {
    userRoles: [],
  
 },

  reducers: {
    setUserCountByRole: (state,action) => {
        state.userRoles = action.payload
    },
    // updateDeleteUserList: (state,action) =>{
    //     const record = action.payload;
    //     state.delUsrList = state.delUsrList.map(rec=>rec.id === record.id ? record : rec);
    // }
 
  },
 
})

// Action creators are generated for each case reducer function
export const { setUserCountByRole } = graphSlice.actions

export default graphSlice.reducer