import React, { useState, useEffect } from "react";
import Content from "./Layout/Content";
import { useLocation } from "react-router-dom";
import AlertDismissible from "./AlertDismissible";
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import IdleTimerContainer from './IdleTimerContainer';
import { setLogout } from "./Store/AllReducer/loginSlice";
import { useSelector, useDispatch } from "react-redux";

function App() {
  let location = useLocation();
  let dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser)


  return (location.pathname === "/login" || location.pathname === "/") ? (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <AlertDismissible />
    </>
  ) : (
    <div>
      <div id="wrapper">
          <>
            <IdleTimerContainer />
            <Content />
            <AlertDismissible />
          </>

      </div>
    </div>
  );
}

export default App;
