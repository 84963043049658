import React, { useState, useEffect } from "react";
import Calender from "../FormFields/Calender";
import DeleteAccountTable from "./DeleteAccountTable";
import { useSelector, useDispatch } from 'react-redux'
import { fetchDeleteMessageList, deleteRecord, reserveRecord } from "../../Store/AllReducer/deleteAccountSlice";

const DeleteAccountList = () => {
    const [selectedDate, setSelectedDate] = useState();
    const { delUsrList } = useSelector((state) => state.delUser);
    const { currentUser } = useSelector((state) => state.login)

    const dispatch = useDispatch()

    useEffect(() => {
        if (selectedDate)
            dispatch(fetchDeleteMessageList({ date: selectedDate }))
    }, [selectedDate]);


    const handleDelete = (recordId) => {
        dispatch(deleteRecord(recordId))

    }
    const handleCancel = (recordId) => {
        dispatch(reserveRecord(recordId))

    }

    return currentUser ? (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="float-right page-breadcrumb">
                        </div>
                        <h5 className="page-title">Delete User Accounts</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-1">
                                        <b>Date :</b>
                                    </div>

                                    <div className="col-6">
                                        <Calender
                                            id="date"
                                            name="date"
                                            onChange={e => setSelectedDate(e.target.value)}
                                            style={{ width: "300px" }}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid " style={{ marginTop: "2%" }}>
                <div className="row card">
                    <DeleteAccountTable listData={delUsrList} handleDelete={handleDelete} handleCancel={handleCancel} />
                </div>
            </div>
        </div>
    ) : null;
};

export default DeleteAccountList;