import { configureStore,combineReducers} from '@reduxjs/toolkit'
import loginSlice from './AllReducer/loginSlice';
import deleteAccountSlice from './AllReducer/deleteAccountSlice';
import helpCenterSlice from './AllReducer/helpCenterSlice';
import graphSlice from './AllReducer/graphSlice';

const combinedReducer = combineReducers({
  login: loginSlice,
  delUser: deleteAccountSlice,
  help: helpCenterSlice,
  graph: graphSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'login/setLogout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};


// export default configureStore({
//   reducer: {
//     login: loginSlice,
//     delUser: deleteAccountSlice,
//     help: helpCenterSlice,

//   },
// })

export default configureStore({
  reducer: rootReducer,
 
});