import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ModalPopup = (props) => 
{
    const {title,msg,show,setShow} =props;
    const handleClose = () => setShow(false);
    return (
        <div>
            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-lg-12">
                        <h6>{msg}</h6>
                    </div>
                    <hr />
                    <div className="col-lg-12">
                        <Button variant="danger" onClick={handleClose} style={{ float: "right", "marginRight":"10px" }}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ModalPopup;