import React, { useState, useEffect } from "react";
import Users from "./Users";
import SubscribedUser from "./SubscribedUser";
import EchartCard from "../../Echart/EchartCard";
import { fetchUserCountByRole } from "../../../Store/AllReducer/graphSlice";
import {useDispatch, useSelector} from "react-redux";
function Dashboard() {
    let dispatch = useDispatch();
    const { userRoles } = useSelector((state) => state.graph);

    useEffect(()=>{
        dispatch(fetchUserCountByRole());
    },[]);

       return (
        <>
        <div> Admin Dashboard </div>
        <div>
            <div className="row col-sm-12 col-md-12 col-lg-12">
                <div className="col-sm-12 col-md-6 col-lg-6">
                <EchartCard content={<Users userRoles={userRoles}/>} />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                <EchartCard content={<SubscribedUser/>} />
                </div>
            </div>

        </div>
        
        
        </>
    );
}

export default Dashboard;