import React, { useEffect, useState } from "react";
import { Echart } from '../../Echart';
function Users({ userRoles }) {
 
const[ option , setOption] = useState();
    useEffect(() => {
        if (userRoles && userRoles.length > 0) {
            let xAxisArr = [];
            let dataArr = [];
            userRoles.forEach(element => {
                xAxisArr.push(element.userType);
                dataArr.push(element.count);
            });

            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'shadow'
                    }
                  },
                xAxis: {
                    type: 'category',
                    data: xAxisArr
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: dataArr,
                        type: 'bar'
                    }
                ]
            };
            setOption(option);
        }


    }, [userRoles]);

   
    return (

        <>
            {option && <Echart option={option} /> }
        </>
    );
}
export default Users;