import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../../API";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import Pusher from "pusher-js";
import ProgressBar from "react-bootstrap/ProgressBar";
import UploadWarningModal from "../../Modals/UploadWarningModal";

const validationSchema = Yup.object({
  file: Yup.string().required("Please Upload Excel/Csv file"),
});

const UploadVerticalMaster = ({ myList, setMyList, currentUser, dispatch, setMessage }) => {
  const ref = React.useRef();
  const myInitialValues = { file: "" };
  let [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [popupShow, setPopupShow] = useState(true);
  const [totalRows, setTotalRows] = useState();
  const [proecessedRows, setProcessedRows] = useState(0);

  useEffect(() => {
    setChannel("" + Date.now());
  }, []);

  const formik = useFormik({
    initialValues: myInitialValues,
    onSubmit: async (values, actions) => {
      await UpVerticalMaster(
        values.file,
        setLoading,
        myList,
        setMyList,
        dispatch, setMessage,
        channel
      );
      actions.setSubmitting(false);
      ref.current.value = "";
    },
    validationSchema,
  });

  useEffect(() => {
    if (channel !== undefined) {
      let i = 0;
      const pusher = new Pusher("fb027f5a97144a25d7b6", {
        cluster: "ap2",
      });

      const channel1 = pusher.subscribe(channel);
      channel1.bind("my-event", function (data) {
        console.log(data.message);
        if (data.message.includes("Warning:::")) {
          if (i === 0) {
            setMyList(!myList);
          }
          setErrorMsg(data.message.split(":::")[1]);
          i++;
        } else if (data.message.includes("Status:::")) {
          if (data.message == "Status:::Total") {
            setTotalRows(data.warningId);
          }

          if (data.message == "Status:::Current") {
            setProcessedRows(data.warningId);
          }
        }
      });
    }
  }, [channel, myList]);

  return currentUser ? (
    <div>
      <div className="col-12">
        <div className="card">
          <form
            id="form-vertical"
            method="post"
            className="form-horizontal"
            onSubmit={formik.handleSubmit}
          >
            <div className="card-header">
              <b>Upload Vertical Master</b>
              <span style={{ float: "right" }}>
                <Link
                  to="#"
                  onClick={() => {
                    download();
                  }}
                >
                  <b>Standard Format</b>
                </Link>
              </span>
            </div>
            <div className="card-body">
              <div className="form-group">
                <div className="col-lg-12 row">
                  <div className="col-lg-12">
                    <input
                      id="file"
                      name="file"
                      type="file"
                      ref={ref}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "file",
                          event.currentTarget.files[0]
                        );
                      }}
                      onBlur={formik.handleBlur}
                      className="form-control"
                    />
                    {formik.errors.file ? (
                      <div className="alert alert-success">
                        {formik.errors.file}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <br />
                {totalRows !== undefined ? (
                  <>
                    <ProgressBar
                      striped
                      animated
                      variant="success"
                      max={totalRows}
                      now={proecessedRows}
                      key={1}
                      label={`${Math.round(
                        (proecessedRows / totalRows) * 100
                      )}%`}
                    />
                  </>
                ) : null}

                {totalRows !== undefined ? (
                  <>
                    <br />
                    <div className="alert alert-dark">
                      Imported {proecessedRows} Records of {totalRows - 1}.
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="card-footer">
              <div className="form-group">
                <center>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </button>
                </center>
              </div>
            </div>
            <div className="col-lg-12">{loading ? <Spinner /> : null}</div>
          </form>
        </div>
      </div>
      {errorMsg !== undefined ? (
        <UploadWarningModal
          errorMsg={errorMsg}
          header={"Vertical Upload Status"}
          setErrorMsg={setErrorMsg}
          setPopupShow={setPopupShow}
          popupShow={true}
        />
      ) : null}
    </div>
  ) : null;
};

async function UpVerticalMaster(
  file,
  setLoading,
  myList,
  setMyList,
  dispatch, setMessage,
  channel
) {
  setLoading(true);

  let fd = new FormData();
  fd.append("file", file);
  fd.append("type", "proctor");
  fd.append("channel", channel);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  await API.post("/Vertical/upload", fd, config)
    .then(function (res) {
      if (res.data.status === "success") {
        setLoading(false);
        dispatch(setMessage({
          show: true,
          msg: res.data.message,
          responseType: res.data.status
        }));
      }
    })
    .catch(function (error) {
      setLoading(false);
    });
}

function download() {
  const serverPath = process.env.REACT_APP_PROJPATH;
  const response = {
    file: serverPath + "standardFormats/verticalMaster.xlsx",
  };
  window.open(response.file);
}

export default UploadVerticalMaster;
