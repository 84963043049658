import React, { useEffect } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import API from "../../API";
import axios from "axios";
import { useState } from "react";

const CronJob = (props) => {
  const [listData, setListData] = useState([]);
  const initialValues = { srno: "", name: "", description: "", action: "" };
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    getCronjobList();
  }, [edit]);

  async function getCronjobList() {
    await API.get("Cronjob/list")
      .then((res) => {
        if (res.data.status === "success") {
          console.log(res.data.data)
          setListData(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err: ", err.response);
        setListData([]);
      });
  }

  const onStatusChangehandler = (changeData)=>{
    const {id,status:st} = changeData;
    const status = st === 1?0:1
    setCronjobList({id,status})
  }

  async function setCronjobList(params) {
    await API.post("Cronjob/edit",params)
      .then((res) => {
        if (res.data.status === "success") {
          edit? setEdit(false):setEdit(true)
          console.log(res.data.status);
        }
      })
      .catch(function (error) {
        console.log("err: ", error.response);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        console.log("Submited Values: ", values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;
        return (
          <div>
            <div
              className="container-fluid add_mobview"
              style={{ marginTop: "100px" }}
            >
              <div className="card">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h3 className="page-title">
                    <center>Cron Job</center>
                  </h3>
                </div>

                <form
                  id="form-config"
                  method="post"
                  className="form-horizontal"
                  onSubmit={handleSubmit}
                >
                  <div className="col-lg-12 col-md-12 col-sm-12 animate__animated animate__pulse animate_slower">
                    <div className="col-lg-12 col-md-12 col-sm-12 card mb-3">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Sr. No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listData.map((obj, index) => (
                            <tr key={index} className="table-row">
                              <td className="col-lg-1 col-md-1 col-sm-1">
                                {obj.id}
                              </td>
                              <td className="col-lg-4 col-md-4 col-sm-4">
                                {obj.name}
                              </td>
                              <td className="col-lg-5 col-md-5 col-sm-5">
                                {obj.description}
                              </td>
                              <td className="col-lg-2 col-md-2 col-sm-2">
                                <div
                                  key={obj.id}
                                  class="custom-control custom-switch"
                                >
                                  <input
                                    checked={obj.status === 1}
                                    key={obj.id}
                                    type="checkbox"
                                    class="custom-control-input"
                                    id={"customSwitches" + obj.id}
                                    onChange={(e) => {
                                      onStatusChangehandler(obj);
                                    }}
                                  />
                                  <label
                                    class="custom-control-label "
                                    for={"customSwitches" + obj.id}
                                  >
                                  </label>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default CronJob;
