import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { hideShow } from "../utils/Helper";

import AdminHome from "../Components/Admin/AdminHome";
import Sidebar from "./Sidebar";
import VerticalMaster from "../Components/Admin/Masters/VerticalMaster";
import HelpMessageList from "../Components/HelpCenter/HelpMessageList";
import DeleteAccountList from "../Components/DeleteAccount/DeleteAccountList";
import SendNotification from "../Components/Notification/SendNotification";
import Dashboard from "../Components/Admin/Dashboard/Dashboard";
import CronJob from "../Components/CronJob/CronJob";
import SubUser from "../Components/Subuser/SubUser";
import PlanMaster from "../Components/PlanMaster/PlanMaster";
import ChildrenProfile from "../Components/ChildrenProfile/ChildrenProfile";
const Content = () => 
{
  const currentUser = useSelector((state) => state.login.currentUser)

  return (
    currentUser ?
      <>
        <Sidebar />
        <div>
          <div className="content-page" id="hidespacecontent">
            <div className="content">
              <Header />
              <div className="page-content-wrapper ">
                <Routes>
                  <Route path="/adminHome" element={<AdminHome />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/verticalMaster" element={<VerticalMaster />} />
                  <Route path="/helpCenter" element={<HelpMessageList />} />
                  <Route path="/deleteAccount" element={<DeleteAccountList/>} />
                  <Route path="/notification" element={<SendNotification />} />
                  <Route path="/cronJob" element={<CronJob />} />
                  <Route path="/SubUser" element={<SubUser />} />
                  <Route path="/PlanMaster" element={<PlanMaster />} />
                  <Route path="/ChildrenProfile" element={<ChildrenProfile />} />
                </Routes>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </>
    :null
  );
};

export default Content;
