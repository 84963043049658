import React, { useRef, useState, useEffect } from 'react';
import { IdleTimer } from './IdleTimer';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import API from './API';
import { useNavigate , useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { setLogout } from './Store/AllReducer/loginSlice';

const IdleTimerContainer = () => {
    const idleTimerRef = useRef(null);
    const { currentUser } = useSelector((state) => state.login);
    let location = useLocation();
    const navigate = useNavigate();
    let dispatch = useDispatch();
    const idleDuration = process.env.REACT_APP_IDLE_DURATION;
    
    const [modalShow, setShow] = useState({
        show: false,
        modalClose: false
    });
    const handleClose = () => setShow({
        show: false,
        modalClose: true
    });
   

    useEffect(()=>{
        if(modalShow.show){
            setTimeout(()=>{
                navigate('/login');
            },30000);//half minute
           
        }
        if(modalShow.modalClose){
            navigate('/login');
        }
    },[modalShow]);
    async function onIdle() 
    {
        if(currentUser)
        {
            setShow({
                ...modalShow,
                show: true,
            });
            let str = '';
            // if(currentUser.role === 'STUDENT')
            // {
            //     str='/login';
            // }
            // else
            // {
            //     str='/adminLogin';
            // }
            str='/login';
            const res = await API.post('Authenticate/logout', { "id":currentUser.id});
            localStorage.clear();
            if (res.data.status === 'Success') 
            {
                navigate(str);
               // setCurrentUser(undefined);//logout redux
               dispatch(setLogout());

            }
        }
    }

    return (
        <>
            <div>
                {currentUser && location.pathname !== 'clearsessionScript' ?
                    <IdleTimer ref={idleTimerRef} timeout={idleDuration * 60 * 1000} onIdle={onIdle}>

                    </IdleTimer>
                : null}
            </div>
            <Modal show={modalShow.show} onHide={handleClose} backdrop="static" size="lg">
                <Modal.Header closeButton style={{ backgroundColor: "OliveDrab", color: "white" }}>
                    <Modal.Title>Important Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-lg-12">
                        <h6>You are Idle for more than {idleDuration} Minutes. Loggining you out of your account.</h6>
                    </div>
                    <hr />
                    <div className="col-lg-12">
                        <Button variant="danger" onClick={handleClose} style={{ float: "right" }}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default IdleTimerContainer;