import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import API from '../../API';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

function SubUser() {
const [list, setList] = useState();
const { SearchBar } = Search;

useEffect(() =>
  {
  getData();
  },
  [list]);

  const getData = () =>{
    API.get("/SubUser/list").then((res) =>
    {
    setList(res.data);
    
    }
    );
};

const options = {
    sizePerPageList: [
        {
            text: '50', value: 50
        },
        {
            text: '500', value: 500
        },
        {
            text: '1000', value: 1000
        },
        {
            text: '10000', value: 10000
        }
    ]
};


  const columns = [
        { text: 'Sr No', dataField: 'id' },
        { text: 'Name', dataField: 'name' },
        { text: 'Email', dataField: 'email' },
        { text: 'Contact no', dataField: 'mobile'},
        { text: 'Plan', dataField: 'plan' },
        { text: 'Type', dataField: 'type' },
        { text: 'Amount', dataField: 'amount' },
        { text: 'Subscription date', dataField: 'created_at' }
  ];




  return (
    <div>
        <div
        className="container-fluid add_mobview"
        style={{ marginTop: "100px" }}
        >
            <div className="card">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h1 className="page-title">
                        <center>Subscribed Users</center>
                    </h1>
                </div>
                { list && 
                    <ToolkitProvider
                        keyField="id"
                        data={list}
                        columns={columns}
                        search
                        pagination={paginationFactory(options)}
                        
                        >
                        {
                            props => (
                                <div>
                                        Search:
                                        <SearchBar {...props.searchProps} />
                                    <div>
                                        <div className="SubUser card-2">
                                            <BootstrapTable   { ...props.baseProps }/>
                                        </div>
                                    
                                    </div> 
                                </div>
                            )
                        }
                    </ToolkitProvider>
                }
            </div>
        </div>
    </div>
  );
}

export default SubUser;

















// import React, { useState, useEffect  } from "react";

// import BootstrapTable from 'react-bootstrap-table-next';
// import API from "../../API";
// import axios from "axios";
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import paginationFactory from 'react-bootstrap-table2-paginator';


// import React, { useState, useEffect } from 'react';
// import BootstrapTable from 'react-bootstrap-table-next';
// import API from '../../API';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import DeleteConfirmationPopUp from "../Modals/DeleteConfirmationModal";

// const SubUser = ({ myList, setMyList, currentUser, dispatch, setMessage }) => {
//     const [loading, setLoading] = useState(false);
//     const [data, setData] = useState();
//     const [delId, setDelId] = useState();
//     const [visible, setVisible] = useState(false);

//     const options = {
//         sizePerPageList: [
//             {
//                 text: '50', value: 50
//             },
//             {
//                 text: '500', value: 500
//             },
//             {
//                 text: '1000', value: 1000
//             },
//             {
//                 text: '10000', value: 10000
//             }
//         ]
//     };

//     useEffect(() => {
//         getSubUserList2(setData, dispatch, setMessage);
//     }, [myList]);


//     const header = getHeader();
//     const myData = getData(data, setVisible);

//     return (
//         currentUser && data !== undefined ?
//             <div style={{ "marginBottom": "20px" }}>
//                 <div className="container-fluid">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="card">
//                                 <div className="card-body">
//                                     <BootstrapTable keyField='srno' data={myData} columns={header} filter={filterFactory()} pagination={paginationFactory(options)} />

//                                     {/* {visible ?
//                                         <DeleteConfirmationPopUp visible={visible} setVisible={setVisible} popupMsg={"Do you really want to delete this Vertical?"} deleteRecord={deleteRecord} id={delId} setInserted={setMyList} inserted={myList} dispatch={dispatch} setMessage={setMessage} setCurPage={1} />
//                                         : null} */}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             : null
//     );
// };


// function getData(data) {
//     let myData = [];
//     let i = 1;

//     data !== undefined && data.map((data, index) => {
//         myData.push({
//             srno: i++,
//             name: data.name,
//             email: data.email,
//             mobile: data.mobile,
//             amount: data.amount,
//             created_at: data.created_at,
//             plan: data.plan,
//             type: data.type,
            
           
//         });
//     });

//     return myData;
// }

// function getHeader() {
//     let myHeader = [
//         { text: 'Sr No', dataField: 'srno' },
//         { text: 'Name', dataField: 'name' },
//         { text: 'Email', dataField: 'email' },
//         { text: 'Contact_no', dataField: 'mobile' },
//         { text: 'Amount', dataField: 'amount' },
//         { text: 'Subscription_date', dataField: 'created_at' },
//         { text: 'Plan', dataField: 'plan' },
//         { text: 'Type', dataField: 'type' }       
//     ];
//     return myHeader;
// }

// async function getSubUserList2(setData, dispatch, setMessage, setMyList, myList) {
//     await API.get("/SubUser/list")
//         .then(function (res) {
//             if (res.data.status === "success") {
//                 setData(res.data.data);
//                 setMyList(!myList);
//             }
//         })
//         .catch(function (error) {

//         });
// }

// export default SubUser;




































































// const SubUser = (props) => {
//   const [listData, setListData] = useState([]);
//   const initialValues = { srno: "", name: "", email: "", mobile: "", amount: "", created_at:"",type:"", plan:"" };
//   const [edit, setEdit] = useState(false);
//   const [status, setStatus] = useState("");
 
//   useEffect(() => {
//     getSubUserList2();
//   }, [edit]);

//   async function getSubUserList2() {
//     await API.get("SubUser/list")
//       .then((res) => {
//         if (res.data.status === "success") {
//           console.log(res.data.data)
//           setListData(res.data.data);
//         }
//       })
//       .catch((err) => {
//         console.log("err: ", err.response);
//         setListData([]);
//       });
//   }
//   return (        
//           <div>
//             <div
//               className="container-fluid add_mobview"
//               style={{ marginTop: "100px" }}
//             >
//               <div className="card">
//                 <div className="col-lg-12 col-md-12 col-sm-12">
//                   <h3 className="page-title">
//                     <center>Subscribed Users</center>
//                   </h3>
//                 </div>
//                   <div className="col-lg-12 col-md-12 col-sm-12 animate__animated animate__pulse animate_slower">
//                     <div className="col-lg-12 col-md-12 col-sm-12 card mb-3">
//                       <table className="table table-bordered">
//                         <thead>
//                           <tr>
//                             <th scope="col">Sr. No.</th>
//                             <th scope="col">Name</th>
//                             <th scope="col">E-mail</th>
//                             <th scope="col">Contact No</th>
//                             <th scope="col">Amount</th>
//                             <th scope="col">Subscribtion Date</th>
//                             <th scope="col">Plan</th>
//                             <th scope="col"> Type</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {listData.map((subscription, index) => (
//                             <tr key={index} className="table-row">
//                               <td className="col-lg-1 col-md-1 col-sm-1">
//                                 {subscription.id}
//                               </td>
//                               <td className="col-lg-3 col-md-3 col-sm-3">
//                                 {subscription.name}
//                               </td>
//                               <td className="col-lg-3 col-md-3 col-sm-3">
//                                 {subscription.email}
//                               </td>
//                               <td className="col-lg-3 col-md-3 col-sm-3">
//                                 {subscription.mobile}
//                               </td>
//                               <td className="col-lg-2 col-md-2 col-sm-2">
//                                 {subscription.amount}
//                               </td>
//                               <td className="col-lg-5 col-md-5 col-sm-5">
//                                 {subscription.created_at}
//                               </td>
//                               {/* <td className="col-lg-5 col-md-5 col-sm-5">
//                                 {subscription.plan}
//                               </td>
//                               <td className="col-lg-5 col-md-5 col-sm-5">
//                                 {subscription.type}
//                               </td> */}
//                             </tr>
//                           ))}
//                         </tbody>

//                         {/* <tbody>
//                           {listData.map((subscription,plan_master, index) => (
//                             <tr key={index} className="table-row">
                             
//                               <td className="col-lg-5 col-md-5 col-sm-5">
//                                 {plan_master.plan}
//                               </td>
//                             </tr>
//                           ))}
//                         </tbody> */}
//                       </table>
//                     </div>
//                   </div>
           
//               </div>
//             </div>
//           </div>
//         );
//       }

// export default SubUser;